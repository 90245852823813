<template>
  <div>
    <Navbar page="Projetos finalizados" link="/painel" nameLink="Painel de Controle" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">

      <h1 class="text-3xl mb-5">Projetos finalizados</h1>

      <div class="grid grid-cols-12 gap-6 mb-3">
        <div class="col-span-12 xl:col-span-10 mb-1 mt-1">
            <input v-model="busca" type="text" name="busca" id="busca" placeholder="Filtrando Nome" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
        </div>
        <div class="col-span-12 md:col-span-2 mb-1 mt-2">
          <button @click="start" class="bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Buscar </button>
        </div>
      </div>

      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Nome
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      CNPJ
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Data Arquivada
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Opções
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="item in list" :key="item._id">
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="text-sm text-gray-500">
                          {{item.nome ? item.nome : ''}}
                        </div>
                      </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                          {{item.cnpj ? item.cnpj : ''}}
                        </div>
                      </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                      <div v-if="item.dataArquivada" class="flex items-center">
                        <div class="text-sm text-gray-500">
                          {{ item.dataArquivada ? item.dataArquivada : '' | moment("DD/MM/YYYY") }}
                        </div>
                      </div>
                    </td>
                  
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">

                      <button
                      @click="ativar(item._id)" 
                      href="#" 
                      class="text-white hover:bg-green-700 bg-green-600 rounded py-2 px-4">
                      Ativar
                      </button>

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination v-model="page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      route: 'empresas',
      list: [],
      page: 1,
      perPage: 10,
      busca: '',
      total: 0,
    }
  },
  methods: {

    async start() {
      const listReq = await this.$http.post(`/v1/${this.route}/list`, { busca: this.busca, limit: this.perPage, desativadas: true });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },
    
    async ativar(id) {
      this.$confirm({
          title: 'Confirmação',
          message: `Tem certeza que deseja ativar essa empresa?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
              const req = await this.$http.put(`/v1/${this.route}/`, { _id: id, ativo: true });
              if (!req.data.success) return this.$vToastify.error(req.data.err);
              this.$vToastify.success("Salvo com sucesso!");
              this.setPage(1);
            }
          }
      });
    },

    async setPage(page) {
      this.page = page;
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      const listReq = await this.$http.post(`/v1/${this.route}/list`, { busca: this.busca, limit, skip, desativadas: true });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    }
  },
  async beforeMount() {
    this.start();
  },
}
</script>